// todo delete this component, all usages was replaces by highlightable.js - is has more high performance, cause use css animations
import React from "react";
import "./accordion.scss";
import anime from "animejs/lib/anime.es.js";
import WatchedElement from "./watchedElement";

const HighlightedLabel = (props) => {
  const handleEnterViewportScrollDown = () => {
    anime.remove(".higlight");

    anime({
      targets: ".highlight",
      width: "100%",
      easing: "easeInOutQuad",
      duration: 1000
    });
  };

  const handleExitViewportScrollUp = () => {
    anime.remove(".higlight");
    anime({
      targets: ".highlight",
      width: "0%",
      easing: "easeInOutQuad",
      duration: 1000
    });
  };
  return (
    <WatchedElement className={props.className} style={props.style} handleEnterViewportScrollDown={handleEnterViewportScrollDown}>
      <div className="highlighted-label-wrapper">
        <h3 className="has-text-weight-bold">{props.text}</h3>
        <div className="highlight" />
      </div>
    </WatchedElement>
  );
};

export default HighlightedLabel;
